html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}
/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle ;
}


fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
body {
  font: normal 700 16px/26px 'Nexa', 'Poppins', sans-serif;
}


//style Maxim Mozzgov
.btn {
  display: inline-block;
  text-transform: uppercase;
  border: none;
  background: #156bf3; /* Old browsers */
  background: -moz-linear-gradient(left, #156bf3 0%, #1af0a4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #156bf3 0%,#1af0a4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #156bf3 0%,#1af0a4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#156bf3', endColorstr='#1af0a4',GradientType=1 ); /* IE6-9 */
  color: #fff;
  font-size: 12px;
  line-height: 1;
  padding: 20px 10px 17px;
  text-align: center;
  width: 200px;
  max-width: 100%;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;
  @include max-lg {
    width: 170px;
  }
  @include max-xs {
    width: 46%;
  }
  &.blue {
    background: #136fff;
  }
  &.green {
    background: #18e0bd;
  }
  &:hover {
    opacity: 0.9;

  }
}

.main_section {
  & .main_swiper {
    & .img {
      -webkit-background-size: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 0;
      padding-bottom: 56%;
      @include max-lg {
        padding-bottom: 70%;
      }
      @include max-md {
        padding-bottom: 80%;
      }
      @include max-sm {
        padding-bottom: 0;
        min-height: 100vh;
      }
    }
    & img {
      width: 100%;
    }
    & .content {
      max-width: 650px;
      color: #fff;
      position: absolute;
      top: calc(50% + 110px);
      transform: translateY(-50%);
      left: 125px;
      @include mmax(1550px){
        left: 50px;
      }
      @include max-lg {
        left: 15px;
      }
      @include max-sm {
        //position: relative;
        top: inherit;
        transform: none;
        bottom: 40px;
      }
      & .title {
        font-size: 36px;
        text-transform: uppercase;
        line-height: 42px;
        letter-spacing: 0.075em;
        @include max-md {
          font-size: 26px;
          line-height: 32px;
        }
        @include max-sm {
          font-size: 24px;
          line-height: 30px;
        }
        @include max-xs {
          font-size: 20px;
          line-height: 28px;
        }
      }
      & p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        @include max-sm {
          font-size: 14px;
          line-height: 24px;
        }
      }
      & .control {
        margin-top: 30px;
      }

    }

  }
}

@mixin content_commercial_residential {
  &.white {
    color: #fff;
  }
  & .title h2,
  & .title h1,
  & .title {
    font-size: 21px;
    line-height: 30px;
    letter-spacing: 0.075em;
    margin: 0;
    text-transform: uppercase;
    @include max-sm  {
      font-size: 18px;
      line-height: 24px;
    }
  }

  & p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #878781;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.025em;
    margin: 20px 0;
    @include max-sm  {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & a:not(.btn) {
    font-weight: 600;
    color: #878781;
    text-decoration: none;
    @include max-sm  {
      color: #74746e;
    }
  }
  &.white a {
    color: #fff;

  }
  &.white p{
    color: #fff;
    @include max-sm  {
      color: #74746e;
    }
  }
  & .control {
    margin-top: 50px;
    @include max-sm {
      margin-top: 30px;
    }
    & a {
      margin-right: 15px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.commercial_residential {
  margin: 15px 0;
  padding: 140px 0;
  @include max-md {
    margin: 0;
  }
  @include mmax(1550px) {
    padding: 70px 0;
  }
  @include max-sm {
    padding: 40px 0;
  }
  & .commercial_residential_wrapper {
    padding: 0 120px;
    font-size: 0;
    position: relative;
    color: #2b2c43;
    @include mmax(1550px) {
      padding: 0 50px;
    }
    @include max-lg {
      padding: 0 15px;
    }
    & .item {
      display: inline-block;
      width: 46%;
      vertical-align: middle;
      @include max-sm {
        width: 100%;
      }
      &.img_item{
        float: right;
        text-align: right;
        cursor: pointer;
        @include max-sm {
          float: none;
          text-align: center;
          width: 100%;
          margin-bottom: 40px;
        }
        & img {
          max-width: 100%;
          display: block;
          @include max-sm {
            margin: 0 auto;
          }
        }
      }
      & .control {
        @include max-sm  {
          text-align: center;
        }
      }

      @include content_commercial_residential;

    }
  }
}


.residential_section {
  margin: 15px 0;
  position: relative;
  @include max-md {
    margin: 0;
  }
  & .residential_wrapper {
    display: flex;
    background: url("../img/residential.jpg") center center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    padding: 180px 120px;
    @include mmax(1550px) {
      padding: 90px 50px;
    }
    @include max-lg {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include max-sm {
      background: none;
      display: block;
      padding-bottom: 50px;
      padding-top: 0;
      text-align: left;
    }
    & .item {
      width: 45%;
      @include max-lg {
        width: 60%;
      }
      @include max-sm {
        width: 100%;
      }
      &.item_img {
        display: none;
        position: relative;
        @include max-sm {
          display: block;
          margin: 0 -15px;
          width: calc(100% + 30px);
        }
        &>.title {
          @include max-sm {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: #fff;
            display: block!important;
            & h2 {
              display: block!important;
            }
          }
        }
        & img {
          width: 100%;
          display: block;
        }
      }
      @include content_commercial_residential;
      & .title h2,
      & .title h1,
      & .title {
        @include max-sm  {
          display: none;
        }
      }
    }
  }
}

.commercial_section {
  margin: 15px 0;
  position: relative;
  @include max-md {
    margin: 0;
  }
  & .commercial_wrapper {
    display: flex;
    background: url("../img/commercial.jpg") center center no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    position: relative;
    padding: 180px 120px;
    @include mmax(1550px) {
      padding: 90px 50px;
    }
    @include max-lg {
      padding-left: 15px;
      padding-right: 15px;
    }
    @include max-sm {
      background: none;
      display: block;
      padding-bottom: 50px ;
      padding-top: 0;
    }
    & .item {
      width: 45%;
      @include max-sm {
        width: 100%;
      }
      @include max-lg {
        width: 60%;
      }
      &.item_img {
        display: none;
        position: relative;
        @include max-sm {
          display: block;
          margin: 0 -15px;
          width: calc(100% + 30px);
        }
        &>.title {
          @include max-sm {
            position: absolute;
            bottom: 15px;
            left: 15px;
            color: #fff;
            display: block!important;
            & h2 {
              display: block!important;
            }
          }
        }
        & img {
          width: 100%;
          display: block;
        }
      }
      @include content_commercial_residential;
      & .title h2,
      & .title h1,
      & .title {
        @include max-sm  {
          display: none;
        }
      }
    }
  }
}

.services_section {
  margin: 15px 0;
  position: relative;
  @include max-md {
    margin: 0;
  }
  @include max-sm {
    background: #e5eeff; /* Old browsers */
    background: -moz-linear-gradient(left, #e5eeff 0%, #e6fff5 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #e5eeff 0%,#e6fff5 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #e5eeff 0%,#e6fff5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5eeff', endColorstr='#e6fff5',GradientType=1 ); /* IE6-9 */
  }
  & .services_wrapper {
    padding: 140px 120px;
    font-size: 0;
    position: relative;
    margin: 0 -40px;
    @include mmax(1550px) {
      padding: 70px 50px;
    }
    @include max-lg {
      padding-left: 15px;
      padding-right: 15px;
      margin: 0 -15px;
    }
    @include max-sm {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    &>.title,
    &>.title h3{
      color: #2b2c43;
      font-size: 36px;
      letter-spacing: 0.075em;
      line-height: 42px;
      text-transform: uppercase;
      padding: 0 20px;
      margin: 0 0 40px;
      @include max-lg{
        padding: 0 7px;
      }
      @include max-sm {
        font-size: 18px;
        line-height: 24px;
      }
    }
    & .item {
      display: inline-block;
      vertical-align: top;
      width: 25%;
      padding: 0 40px;
      margin: 15px 0 20px;
      font-size: 0;
      @include max-md {
        width: 50%;
      }

      @include max-lg {
        padding: 0 15px;
      }
      @include max-sm {
        width: 100%;
      }

      & p {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        color: #878781;
        @include max-sm {
          margin: 0;
          line-height: 1.6;
        }
      }
      & .description {
        @include max-sm {
          display: inline-block;
          width: 80%;
          vertical-align: middle;
          padding-left: 20px;
        }
        & > .title {
          color: #18e0bd;
          font-size: 14px;
          text-transform: uppercase;
          @include max-sm {
            line-height: 1.2 ;
          }
        }
      }
      & .img {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 35px;
        //text-align: center;
        @include max-sm {
          display: inline-block;
          width: 20%;
          vertical-align: middle;
          height: auto;
        }

        & img {
          display: block;
          max-width: 100%;
          @include max-sm {
            max-width: 90%;
          }
          //margin: 0 auto;
        }
      }
    }
  }
}
.testimonials_section {
  margin: 15px 0;
  position: relative;
  @include max-sm {
    margin: 0;
  }
  & .testimonials_wrapper {
    font-size: 0;
    padding: 100px 120px;
    background: #136fff; /* Old browsers */
    background: -moz-linear-gradient(left, #136fff 0%, #19feab 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #136fff 0%,#19feab 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #136fff 0%,#19feab 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#136fff', endColorstr='#19feab',GradientType=1 ); /* IE6-9 */
    @include mmax(1550px) {
      padding: 50px 50px;
    }
    @include max-lg {
      padding-left: 15px;
      padding-right: 15px;
    }
    & .title h3,
    & .title {
      text-transform: uppercase;
      color: #fff;
      font-size: 36px;
      letter-spacing: 0.075em;
      line-height: 42px;
      margin: 0 0 40px;
      @include max-sm {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
    & .item {
      display: inline-block;
      width: 50%;
      vertical-align: top;
      color: #fff;
      @include max-md {
        width: 100%;
      }
      & .swiper-slide {
        font-size: 0;
      }
      & .text {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 150px);
        padding-right: 30px;
        text-align: right;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.025em;
        & strong {
          font-weight: 600;
        }
        & span {
          display: block;
        }
        @include max-sm {
          text-align: left;
        }
      }
      & .img {
        border-radius: 50%;
        overflow: hidden;
        display: inline-block;
        vertical-align: middle;
        max-width: 150px;
      }
    }
  }
  & .swiper-slide:not(.swiper-slide-active){
    opacity: 0 !important;
  }
}



.backdrop {
  background: rgba(43, 44, 67, 0.85);
  position: fixed;
  width: 100%;
  height: 100%;
}
.popup {
  position: fixed;
  z-index: 9997;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  display: none;
  overflow-y: auto;
  overflow-x: hidden;
}
.popup.active .popup_wrapper {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.7s cubic-bezier(0.12, 0.51, 0.41, 1.35);
  -o-transition: all 0.7s cubic-bezier(0.12, 0.51, 0.41, 1.35);
  transition: all 0.7s cubic-bezier(0.12, 0.51, 0.41, 1.35);
  opacity: 1;
}
.popup_wrapper {
  max-width: 1000px;
  width: 100%;
  background: #f0f0f0;
  margin: 0 auto;
  padding: 0;
  top: 15%;
  z-index: 9999;
  position: relative;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.7s cubic-bezier(0.57, -0.31, 0.25, 1);
  -o-transition: all 0.7s cubic-bezier(0.57, -0.31, 0.25, 1);
  transition: all 0.7s cubic-bezier(0.57, -0.31, 0.25, 1);
}
.swiper_popup_gallery {
  & img {
    display: block;
    width: 100%;
  }
}
.close_popup {
  position: absolute;
  width: 27px;
  height: 27px;
  background: url(../img/icons/icon-close.png) center center no-repeat;
  right: 10px;
  top: -35px;
  cursor: pointer;
}
