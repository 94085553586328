.footer_wrapper {
  position: relative;
  background: #2b2c43;
  color: #fff;
  padding: 120px 125px 80px;
  font-size: 0;
  @include mmax(1550px) {
    padding: 60px 50px 40px;
  }
  @include max-lg {
    padding-left: 15px;
    padding-right: 15px;
  }
  & .title {
    text-transform: uppercase;
    margin-bottom: 40px;
    & h2 {
      font-size: 36px;
      line-height: 1.2;
      margin: 0;
      @include max-sm {
        font-size: 18px;
      }
    }
    & span {
      display: block;
      font-size: 14px;
      @include max-sm {
        font-size: 12px;
      }
    }
  }
  & .footer_contact {
    display: inline-block;
    vertical-align: top;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    width: 400px;
    max-width: 100%;
    margin-bottom: 30px;
    @include max-md {
      width: 100%;
    }
    & p {
      font-size: 12px;
      &:first-child{
        margin-top: 0;
      }
    }
    & a {
      color: #fff;
      font-weight: 600;
      text-decoration: none;
      margin-left: 10px;
    }
  }
  & .footer_form {
    margin-bottom: 50px;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 400px);
    @include max-md {
      width: 100%;
    }
    & .message_form{
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      margin: 0 -40px;
      @include max-lg {
        margin: 0 -15px;
      }
      & .btn {
        padding: 20px 10px;
        margin-bottom: 15px;
      }
      & .group_field {
        display: inline-block;
        width: 50%;
        font-size: 0;
        padding: 0 40px;
        vertical-align: top;
        color: #fff;
        @include max-lg {
          padding: 0 15px;
        }
        @include max-xs {
          width: 100%;
        }
        & textarea {
          height: 101px;
          resize: none;
          display: block;
        }
        & textarea,
        & input {
          color: #fff;
          font-size: 12px;
          border: none;
          background: #37384e;
          width: 100%;
          padding: 15px 10px;
          margin-bottom: 15px;
          &::-webkit-input-placeholder {color:#fff;}
          &::-moz-placeholder          {color:#fff;}
          &:-moz-placeholder           {color:#fff;}
          &:-ms-input-placeholder      {color:#fff;}
          &:focus {
            outline: 2px solid #ccc;
          }
        }
      }
    }
  }
  & .footer_copyright {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    font-size: 0;
    position: relative;
    @include max-sm {
      width: 100%;
    }
    & img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 25px;
    }
    & p {
      display: inline-block;
      vertical-align: middle;
      font-size: 8px;
    }
  }
  & .footer_nav {
    display: inline-block;
    vertical-align: middle;
    width: 50%;
    text-align: right;
    @include max-md {
      display: none;
    }
    & ul {
      margin: 0;
      padding: 0;
      font-size: 0;
      & li {
        display: inline-block;
        font-size: 8px;
        margin-left: 50px;
        &:first-child{
          margin-left: 0;
        }
        & a{
          text-transform: uppercase;
          font-size: 8px;
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
}