.header-container {
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 100;
}

.header_wrapper {
  position: relative;
  background: #2b2c43;
  min-height: 110px;
  @include max-lg{
    min-height: 75px;
  }
  & .logo {
    width: 230px;
    @include max-lg{
      width: 170px;
    }
    & img{
      display: block;
      width: 100%;
    }
  }

  & .header_menu {
    position: absolute;
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    @include max-sm {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      transform: none;
      top: 75px;
      background: #2b2c43;
      display: none;
    }
   & ul{
     margin: 0;
     padding: 0;
     font-size: 0;
     & li {
       display: inline-block;
       font-size: 10px;
       margin-left: 50px;
       @include max-lg {
         margin-left: 30px;
       }
       @include max-sm {
         font-size: 14px;
         display: block;
         margin: 20px 30px;
       }
       @include min-md {
         &:first-child{
           margin-left: 0;
         }
       }
       & a{
         color: #fff;
         text-transform: uppercase;
         text-decoration: none;
       }
     }
   }
    & ul.mobile_add_menu {
      margin-top: 40px;
      font-size: 10px;
      color: #fff;
      padding: 0;
        & li {
          display: block;
          margin: 20px 30px;
          font-size: 10px!important;
          & a {
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            font-size: 10px!important;
          }
        }
      @include min-md{
        display: none;
      }
    }
  }


}
.menu_block {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}
//
// menu hamburger
//
.menu_open_close {
  cursor: pointer;
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0 auto;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  z-index: 150;
  display: none;
  @include max-sm {
    display: block;
  }
  &.open span:nth-child(4),
  &.open span:nth-child(1) {
    top: 18px;
    width: 0;
    left: 50%
  }
  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg)
  }
  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg)
  }
  & span {
    display: block;
    position: absolute;
    height: 1px;
    width: 100%;
    background: #fff;
    border-radius: 0;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    &:nth-child(1) {
      top: 0
    }
    &:nth-child(2),
    &:nth-child(3){
      top: 8px
    }
    &:nth-child(4) {
      top: 16px
    }

  }
}
